@media print {

  #section-to-print, #section-to-print * {
    visibility: visible;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  #section-to-print {
    //position: absolute;
    left: 0;
    top: 0;
  }

  @page {
    size: auto;
    margin-bottom: 0;
    margin-top: 0;
    margin: 0;
  }

  body {
    -webkit-print-color-adjust: exact;
    padding: 30px 0;

    * {
      visibility: hidden;
    }
  }

  .page-break {
    page-break-after: always;
  }

  a[href]:after {
    content: none !important;
  }

  a {
    display: none !important;
  }

  .url-block {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #fff;

    & > div {
      text-align: center;
      border-top: 1px solid #5e72e4;
      width: 80%;
      padding-top: 10px;
      margin: auto;
    }
  }
  .date-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 999;
    background-color: #fff;
  }
}

@media print {
  .container {
    margin: auto;
    padding: 30px 40px;
  }

  .table-fill {
    background: white;
    border-radius: 3px;
    border-collapse: collapse;
    margin: auto;
    padding: 5px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    th {
      color: #FFF;
      background: #2980b9;
      font-size: 13px;
      font-weight: 700;
      padding: 4px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      vertical-align: middle;
      text-align: center;
    }

    th:first-child {
      border-top-left-radius: 3px;
    }

    th:last-child {
      border-top-right-radius: 3px;
      border-right: none;
    }

    tr {
      border-top: 1px solid #C1C3D1;
      //border-bottom-: 1px solid #C1C3D1;
      color: #666B85;
      font-size: 16px;
      font-weight: normal;
      text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
    }

    tr:first-child {
      border-top: none;
    }

    tr:last-child {
      border-bottom: none;
    }

    tr:nth-child(odd) td {
      background: #EBEBEB;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 3px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 3px;
    }

    td {
      background: #FFFFFF;
      padding: 1px 5px;
      text-align: left;
      vertical-align: middle;
      border-right: 1px solid #C1C3D1;
    }

    td:last-child {
      border-right: 0px;
    }

    th.text-left {
      text-align: left;
    }

    th.text-center {
      text-align: center;
    }

    th.text-right {
      text-align: right;
    }

    td.text-left {
      text-align: left;
    }

    td.text-center {
      text-align: center;
    }

    td.text-right {
      text-align: right;
    }

  }

  .titlex {
    position: fixed;
    left: 5px;
    top: 20px;
    height: 80px;
    width: 5px;
    background-color: #5e72e4;
  }
}