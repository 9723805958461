.dropzoneContainer {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  font-size: 0.75rem;
}

.dropzoneButton {
  $px: 20px;
  height: 40px;
  border: 2px dashed;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 $px;
  margin: 0;
  display: inline-flex;
  align-items: center;
}

.error {
  $boxShadow: 0 0 5px 0 rgba(255, 0, 0, 0.75);
  box-shadow: $boxShadow;
  -webkit-box-shadow: $boxShadow;
  -moz-box-shadow: $boxShadow;
  border: 1px solid red;
}

.dropzoneLabel {
  span {
    display: inline-block;
    width: 60%;
    font-size: 10px;
  }
}

.thumb {
  border: 1px solid #eaeaea;
  border-radius: 2px;
  padding: 4px;
  box-sizing: border-box;
  margin-bottom: 10PX;
  text-align: center;
  background-color: #fefefe;
}

.acceptStyle {
  @extend .dropzoneButton;
  border-style: solid;
  border-color: #6c6;
  background-color: #eee;
}

.rejectStyle {
  @extend .dropzoneButton;
  border-style: solid;
  border-color: #c66;
  background-color: #eee;
}

.img {
  object-fit: cover;
  height: 300px;
}