$primary-color: #91b5c9;

.img-avatar {
  height: rem(140px);
  width: auto;
  margin: 15px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: contain;
  transition: all 0.35s ease;

  &:hover {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    box-shadow: 0 0 15px 15px $primary-color;
    -webkit-box-shadow: 0 0 15px 15px $primary-color;
    transform: scale(1.05);
  }
}

.img-select {
  @extend .img-avatar;
  box-shadow: 0 0 15px 15px $primary-color;
  -webkit-box-shadow: 0 0 15px 15px $primary-color;
}

.img-no-select {
  @extend .img-avatar;
  filter: opacity(.3);
}

.c-pointer {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 56, 73, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #00BCEA;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.siniestro-caducado {
  &:hover {
    color: $danger;
  }
}

.card-fact {
  border: 1px solid black;
  min-height: 36px;
  word-break: break-word;
  @extend .p-1;
  display: inline-flex;
  align-items: center;
}

.card-factura-checkbox {
  text-align: center;

  input[type="checkbox"] {
    cursor: pointer;
    transform: scale(1.2);
  }
}

.text-custom {
  background: #ffe2e2 !important;
  opacity: 1;
}

.mi-div .pagination-top .-pagination {
  border-bottom: 1px solid white;
  margin-bottom: 15px;
}

.btn-active-border-0 {
  &:active {
    border: none;
  }
}